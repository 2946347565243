interface Environment {
  isProduction: boolean;
  env: "production" | "preview" | "development";
  kycChainId: number;
  apiUrl: string;
  convexUrl: string;
  quadrataApiKey: string;
  sentryDsn: string;
  clerkPublisherKey: string;
  clerkDomain: string;
}

if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
  throw new Error("missing NEXT_PUBLIC_VERCEL_ENV");
}

if (!process.env.NEXT_PUBLIC_KYC_CHAIN_ID) {
  throw new Error("missing NEXT_PUBLIC_KYC_CHAIN_ID");
}

if (!process.env.NEXT_PUBLIC_API_URL) {
  throw new Error("missing NEXT_PUBLIC_API_URL");
}

if (!process.env.NEXT_PUBLIC_CONVEX_URL) {
  throw new Error("missing NEXT_PUBLIC_CONVEX_URL");
}

if (!process.env.NEXT_PUBLIC_QUADRATA_API_URL) {
  throw new Error("missing NEXT_PUBLIC_QUADRATA_API_URL");
}

if (!process.env.NEXT_PUBLIC_SENTRY_DSN) {
  throw new Error("missing NEXT_PUBLIC_SENTRY_DSN");
}

if (!process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY) {
  throw new Error("missing NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY");
}

if (!process.env.NEXT_PUBLIC_CLERK_DOMAIN) {
  throw new Error("missing NEXT_PUBLIC_CLERK_DOMAIN");
}

export const config: Environment = {
  isProduction: process.env.NEXT_PUBLIC_VERCEL_ENV === "production",
  env: process.env.NEXT_PUBLIC_VERCEL_ENV as Environment["env"],
  kycChainId: +process.env.NEXT_PUBLIC_KYC_CHAIN_ID,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  convexUrl: process.env.NEXT_PUBLIC_CONVEX_URL,
  quadrataApiKey: process.env.NEXT_PUBLIC_QUADRATA_API_URL,
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  clerkPublisherKey: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
  clerkDomain: process.env.NEXT_PUBLIC_CLERK_DOMAIN,
};
